import {clientId,tenantId, scopes } from '../StringConstants'

export const msalConfig = {
    auth: {
      authority: 'https://login.microsoftonline.com/' + tenantId,
      clientId: clientId,
      redirectUri: window.msalConfig.redirectUri,
    },
    cache: {
      cacheLocation: 'localStorage',
      //storeAuthStateInCookie: true
    }
  }
  export const loginRequest = {
    scopes: scopes
  }

