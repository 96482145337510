
  export function setUserPreferedLanguage(languageCode, language) {
    return {
      type: "SET_LANGUAGE_CODE",
      payload: languageCode,
      language:language
    };
  }
  export function setLanguageTranslations(array) {
    return {
      type: "SET_LANGUAGE_TRANSLATIONS",
      payload: array,
    };
  }
  