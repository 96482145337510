const appReducer = (
    state = {
    languageCode:'',
    language:'',
    languageTranslationArray:{},
    },
    action
  ) => {
    switch (action.type) {
          case "SET_LANGUAGE_CODE":
          state = {
            ...state,
            languageCode: action.payload,
            language:action.language
          };
          break;
          case 'SET_LANGUAGE_TRANSLATIONS':
          state = {
            ...state,
            languageTranslationArray: action.payload,
          };
          break;
       
    }
    return state;
  };
  
  export default appReducer;
  