import React from 'react';
import ReactDOM from 'react-dom';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from "react-redux";
//import * as serviceWorker from './serviceWorker';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../src/services/authProvider";
import { MsalProvider } from "@azure/msal-react"

import store from "./store";

export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);



/**********/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
