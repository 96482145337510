import React, { PureComponent } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { ENVIRONMENT } from "./StringConstants";
// import * as api from "./services/API";
import * as api from './services/apiAction'
import { setUserPreferedLanguage, setLanguageTranslations } from "./actions/AppActions";
import AuthContext from "./services/Auth";
import Logo from '../src/Images/synops.png';
import userIcon from '../src/Images/profileicon.png';
import { connect } from "react-redux";
import help from '../src/Images/Help.png';
import feedback1 from '../src/Images/Feedback1.svg';
import feedback from '../src/Images/Feedback.svg';
import general from '../src/Images/General.svg';
import signal from '../src/Images/signal.svg';
import feedbackSaved from '../src/Images/check-circle-regular.svg';
import signalSelected from '../src/Images/signal_selected.svg';
import generalSelected from '../src/Images/General_Selected.svg';
import newIdea from '../src/Images/FeedbackSupport.svg';
import newIdeaSelected from '../src/Images/feedselected.svg';
import accLogo from '../src/Images/acc-logo.png';
import dropdownArrow from '../src/Images/drop-down-arrow.png';
import editgreyIcon from '../src/Images/edit-grey-icon.svg';
import hmcccircleClose from '../src/Images/hmcc-circle-close.svg';
import profileIconHome from '../src/Images/profileicon .svg';
import iconCommandCentre from '../src/Images/Command Centre.png'
import iconDemo from '../src/Images/Demo.png'
import iconuhHo from '../src/Images/Uh,oh.png'
import iconDealView from '../src/Images/Deal View.png'
import {
  faSignOutAlt, faPlusCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../src/App.css'
import "../src/css/fonts/fonts.css";
import "../src/css/styles.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { withMsal } from "@azure/msal-react";
import { loginRequest } from "../src/services/authProvider";
import { BrowserUtils } from "@azure/msal-browser";



const guid = () => {
  const S4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0, 3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}


class App extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      hasDealViewAccess: false,
      hasCCAccess: false,
      userDetails: {},
      isSisenseConnectCalled: false,
      showFeedbackModal: false,
      feedbackSubject: '',
      feedbackBody: '',
      type: 'general',
      generalSuggestions: [{ label: 'Issue', value: 'SynOps Hub Issue' }],
      newIdeaSuggestions: [{ label: 'Command Center', value: 'Command Center' }, { label: 'Deal View', value: 'Deal View' }],
      hasDealViewAccess: false,
      hasCCAccess: false,
      userDetails: {},
      demoPopup: false,
      feedbackSelected: null,
      LoggedInUser: {},
      hideProfileHolderDiv: true,
      isCheckedTheme: true,
      openedDropdown: "",
      searchedLanguageText: '',
      languageList: [],
      languageListFiltered: [],
      feedbackSubmitted: false,
      openSubmit: false,
      feedbackSend: false,
      passwordCopiedCC: false,
      passwordCopiedDV: false,
      commentText: "Please provide the issue details here.",
      selectedFile: [],
      fileSizeError: false,
      fileExtensionError: false,
      messageType: "reportanissue",
      popUpText: "issue report",
      submittedIssue: "Issue Report",
      fileCount: 0,
      totalfileCountError: false,
      duplicateFileErrorMsg: false
    };


  }

  login = () => {
    const accounts = this.props.msalContext.accounts;
    const msalInstance = this.props.msalContext.instance;

    msalInstance.handleRedirectPromise().then(tokenResponse => {
      if (!tokenResponse) {
        if (accounts.length === 0) {
          msalInstance.loginRedirect(loginRequest);
        } else if (accounts.length === 1) {
          this.getLanguageList();
        }
      } else {
        this.getLanguageList();
      }
    }).catch(err => {
      // Handle error
      console.error(err);
    });

  }

  componentDidMount() {
    if (ENVIRONMENT !== "local") {
      this.login();
    } else {
      this.getLanguageList();
    }
  }

  sendFeedback = (desc, messageType, file, application) => {
    let formData = new FormData();
    if (file.length > 0) {

      this.state.selectedFile.map((item, i) => {
        formData.append("file" + i, item, item.name);
      })
    }
    formData.append("messageType", messageType);
    formData.append("description", desc);
    formData.append("app", application);
    api.post(`emailmessages`, formData, true).then((resp) => {
      if (resp.length > 0) {
        this.setState({ feedbackSend: resp });
      }
    });
  }


  getLanguageList = () => {
    api
      .get(`languages`)
      .then((res) => {
        const langList = res;
        this.setState({
          languageList: langList,
          languageListFiltered: langList
        }, () => this.getUserDetails())
      })
      .catch((error) => {

      });
  }

  logout = () => {
    const msalInstance = this.props.msalContext.instance;
    msalInstance.logoutRedirect({
      account: this.props.msalContext.accounts[0],
      onRedirectNavigate: () => !BrowserUtils.isInIframe()
    })
    sessionStorage.clear();
    localStorage.clear();
    (() => {

      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    })();
  };

  redirectPage = (url) => {
    window.open(url, "_self")
  }
  _handleChangeTheme = () => {
    this.setState({ isCheckedTheme: !this.state.isCheckedTheme });
  }
  democlick = (e) => {
    e.preventDefault();
    this.setState({
      demoPopup: !this.state.demoPopup
    });
  }
  hideModal = () => {
    this.setState({
      demoPopup: false,
      passwordCopiedCC: false,
      passwordCopiedDV: false
    });
  }

  handleProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: false,
      openedDropdown: ""
    })

  }

  handleCloseProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: true
    })
  }
  handleFile = (e) => {
    e.preventDefault();
    let images = e.target.files;
    var i;
    this.setState({
      fileSizeError: false,
      fileExtensionError: false,
      totalfileCountError: false,
      duplicateFileErrorMsg: false
    });
    if (images) {
      if (this.state.selectedFile.length + e.target.files.length > 3) {
        this.setState({ totalfileCountError: true });
        return;
      }
      var allFileSizes = 0;
      this.state.selectedFile.map(a => {
        let size = (a.size / 1048576);
        allFileSizes = allFileSizes + size;

      });
      for (i = 0; i < e.target.files.length; i++) {
        var repeat = this.state.selectedFile.filter(a => {
          if (a.name === images[i].name) {
            return a;
          }
        })
        var fileSize = (images[i].size / 1048576);
        console.log("filesize", fileSize, allFileSizes);
        if (!images[i].name.match(/.(jpg|png|pdf|docx)$/i)) {
          this.setState({ fileExtensionError: true });
        }
        else if (fileSize + allFileSizes > 15) {
          this.setState({ fileSizeError: true });
        }
        else if (repeat.length > 0) {
          this.setState({
            duplicateFileErrorMsg: true
          })
        }
        else {
          this.state.selectedFile.push(images[i]);

          //  this.state.fileContent.push(images[i]);

        }

      }
    }
    if (!this.state.fileExtensionError && !this.state.fileSizeError && !this.state.totalfileCountError && !this.state.duplicateFileErrorMsg) {
      this.setState({
        selectedFile: this.state.selectedFile,

      })
    }
    e.target.value = '';
  };

  deleteIcon = (e) => {
    var index = e;
    if (index !== -1) {
      this.state.selectedFile.splice(index, 1);
      this.setState({ selectedFile: this.state.selectedFile });
    }
    if (this.state.selectedFile.length <= 3) {
      this.setState({
        totalfileCountError: false
      })
    }
  };

  handleMessageChange = (key, value) => {
    switch (key) {
      case "Report an Issue":
        this.setState({
          commentText: "Please provide the issue details here.",
          messageType: "reportanissue",
          popUpText: "issue report",
          submittedIssue: "Issue Report"
        });
        break;
      case "Access":
        this.setState({
          commentText: "Please provide the E-mail, Client name & Purpose of access.",
          messageType: "access",
          popUpText: "access request",
          submittedIssue: "Access Request"
        });
        break;
      case "Feedback":
        this.setState({
          commentText: "Please provide suggestions or feedback here.",
          messageType: "feedback",
          popUpText: "feedback",
          submittedIssue: "Feedback"
        });
        break;

    }

  }

  onChangeLanguage = (e) => {
    let filtered = [...this.state.languageList]

    let searchedText = e.target.value.toLowerCase().trim()
    this.setState({
      languageListFiltered: filtered.filter(x => x.languageDescription.toLowerCase().includes(searchedText)),
      searchedLanguageText: e.target.value
    })
  }

  onSelectLanguage = (language) => {
    this.setState({
      hideLanguageDiv: true,
      openedDropdown: "",
      languageListFiltered: this.state.languageList
    })
    this.props.setUserPreferedLanguage(language.languageCode, language.languageDescription);
    let selectedLanguage = language.languageCode
    let body = {
      Id: this.state.userDetails.id,
      Language: language.languageCode,
      ShowGlobe: true,
      Theme: "blue",
      Application: "CommandCenter"
    }
    api.post(`userpreference`, body)
      .then((res) => {
        if (res === 'Success') {
          this.getTranslations(selectedLanguage)
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });

  }

  getTranslations = (languageCode) => {
    api
      .get(`translatedtext/${languageCode}`)
      .then((res) => {
        let literals = {};
        literals[languageCode] = { translation: res };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: languageCode,
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
        this.props.setLanguageTranslations(res);
      })
      .catch((err) => {
        let literals = {};
        literals["en"] = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: "en",
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
      })
  }
  getTranslatedText = (key) => {
    let value = "";
    value = this.props.app.languageTranslationArray[key] ? this.props.app.languageTranslationArray[key] : key;
    return value;
  }
  redirectToHomePage = () => {
    window.open('/', "_self")
  }

  getUserDetails = () => {
    //local
    let enterpriseId = '';
    if (ENVIRONMENT === 'local') {
      enterpriseId = "tejinder.a.singh";
      this.setState({ profileName: enterpriseId });
    }
    else {
      let userName = this.props.msalContext.accounts.length > 0 && this.props.msalContext.accounts[0].username;
      enterpriseId = userName.split("@")[0];
      this.setState({ profileName: enterpriseId });

    }
    api
      .get(`useriddetails`)
      .then((res) => {
        //Check if it is a duplicate entry
        if (res.status == 400) {
          this.redirectToHomePage()
          this.setState({ invalidUser: true });
          this.props.setUserPreferedLanguage('en', 'English');
        }
        else {
          const userDetails = res;
          this.setState({
            userDetails: userDetails,
            hasCCAccess: res && res.appIds && res.appIds.includes(1),
            hasDealViewAccess: res && res.appIds && res.appIds.includes(2),
          })
          let langText = (this.state.languageList.find(obj => obj.languageCode == userDetails.userPreference.language)).languageDescription;
          this.props.setUserPreferedLanguage(userDetails.userPreference.language, langText);
          this.getTranslations(userDetails.userPreference.language);
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry
        // if (error.response.data.status == 400) {
        //   this.setState({
        //     invalidUser: true,
        //   });
        // }
        this.props.setUserPreferedLanguage('en', "English");
      });
  };
  // generateMailScript = () => {
  //   if (this.state.feedbackSubject &&
  //     this.state.feedbackBody) {

  //     let to = "Synops-Support@accenture.com";
  //     let subject = encodeURIComponent(this.state.feedbackSubject);
  //     let msgBody1 = encodeURIComponent(this.state.feedbackBody);
  //     let msgBody2 = "Hi Support Team%0D%0A%0D%0APlease refer to below issue and assist with resolution.%0D%0A%0D%0A";
  //     let message = '';
  //     let msgBody3 = "Issue description:" + msgBody1;
  //     let msgBody4 = "%0D%0AUser impacted email ID%20%3A%0D%0AApplication been accessed%20%3A%0D%0A";


  //     // email.className = 'not'
  //     message = 'mailto:' + to;
  //     if (subject || msgBody1) message += '?';
  //     if (subject) message += 'subject=' + subject;
  //     if (subject && msgBody1) message += '&body=' + msgBody2 + msgBody3 + msgBody4;
  //     if (!subject && msgBody1) message += 'body=' + msgBody2 + msgBody3 + msgBody4;

  //     this.setState({ showFeedbackModal: false });
  //     window.open(message);
  //   }
  // }

  // generateNewIdeaScript = () => {
  //   if (this.state.feedbackSubject &&
  //     this.state.feedbackBody) {

  //     let to = "Synops-Support@accenture.com";
  //     let subject = "SynOps Hub Feedback";//encodeURIComponent(this.state.feedbackSubject);
  //     let msgBody1 = encodeURIComponent(this.state.feedbackBody);
  //     let msgBody2 = "Hi SynOps Hub Support Team%0D%0A%0D%0APlease refer to the below feedback.%0D%0A%0D%0A";
  //     let message = '';
  //     let msgBody3 = "Feedback: " + msgBody1;
  //     let msgBody4 = "%0D%0A%0D%0AUser email ID:%0D%0A%0D%0A";
  //     let msgBody5 = "New Idea for which application: " + this.state.feedbackSubject;


  //     // email.className = 'not'
  //     message = 'mailto:' + to;
  //     if (subject || msgBody1) message += '?';
  //     if (subject) message += 'subject=' + subject;
  //     if (subject && msgBody1) message += '&body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5;
  //     if (!subject && msgBody1) message += 'body=' + msgBody2 + msgBody3 + msgBody4 + msgBody5;

  //     this.setState({ showFeedbackModal: false });
  //     window.open(message);
  //   }
  // }
  generateNewIdeaScript = () => {
    if (this.state.feedbackSubject && this.state.feedbackBody) {
      this.setState({ openSubmit: true });
    }
  }
  hideFeedbackModal = () => {
    this.setState({
      showFeedbackModal: false
    })
  }
  hideSubmit = () => {
    this.setState({
      openSubmit: false
    })
  }
  feedbackSubmit = () => {
    this.setState({
      feedbackSubmitted: true,
      openSubmit: false,
      feedbackBody: '',
      feedbackSubject: ''
    })
    this.sendFeedback(this.state.feedbackBody, this.state.messageType, this.state.selectedFile, this.state.feedbackSubject);
  }
  feedbackSubmitNew = () => {
    this.setState({
      feedbackSubmitted: false,
      feedbackBody: '',
      feedbackSelected: null,
      selectedFile: [],
      commentText: "Please provide the issue details here.",
      messageType: "reportanissue",
      popUpText: "issue report",
      submittedIssue: "Issue Report",
      fileSizeError: false,
      fileExtensionError: false,
      totalfileCountError: false,
      duplicateFileErrorMsg: false
    })
  }
  copyPasswordHandler = (text, app) => {
    navigator.clipboard.writeText(text)
    app === 'CC' ? this.setState({ passwordCopiedCC: true, passwordCopiedDV: false }) :
      this.setState({ passwordCopiedDV: true, passwordCopiedCC: false })

  }

  handleFeedbackChange = (key, value) => {
    switch (key) {
      case "type":
        this.setState({
          type: value,
          feedbackSubmitted: false,
          commentText: "Please provide the issue details here.",
          selectedFile: [],
          fileExtensionError: false,
          totalfileCountError: false,
          fileSizeError: false,
          duplicateFileErrorMsg: false
        });
        break;
      case "suggestion":
        this.setState({
          feedbackSubject: value.value,
          feedbackSelected: value
        });
        break;
      case "feedbackMessage":
        this.setState({
          feedbackBody: value,
        });

        break;
      default:
        break;
    }
  };

  render() {
    console.log(this.state.selectedFile, "selected");
    let hideModal = () => {
      this.setState({ showFeedbackModal: false });
    }

    let openFeedbackModal = () => {
      this.setState({
        showFeedbackModal: true,
        feedbackSubject: '',
        feedbackBody: '',
        type: 'productInfo',
        feedbackSelected: null,
        totalfileCountError: false,
        duplicateFileErrorMsg: false,
        selectedFile: [],
        fileExtensionError: false,
        fileSizeError: false,
        messageType: "reportanissue",
        popUpText: "issue report",
        submittedIssue: "Issue Report",
      })
    }
    return (
      <div className='DarkTheme'>
        <Modal

          backdrop={false}
          show={this.state.demoPopup}
          onHide={this.hideModal}
          centered
          dialogClassName="demo-popup DemoModal"
          size="xl"
        >
          <Modal.Header closeButton>

          </Modal.Header>
          <Modal.Body>
            <div className="flex-container">
              <div className="demo-content">
                <img className='icon-CmdCenter icon' src={iconCommandCentre}></img>
                <h5 className="demoName">Command Center Demo</h5>
                <p className="graphikregular font-italic pwd-align mb-4"><u className='copy_Password' onClick={() => this.copyPasswordHandler('OpsInteractive', 'CC')}>{this.state.passwordCopiedCC ? `Password Copied` : `Click here to copy the password for Command Center`}</u></p>
                {/* <p className="graphikregular pwd-align mb-3"></p> */}
                <button className='getStarted'>
                  <a href="https://accentureoperations.invisionapp.com/share/RQ12CYZBJFS2" target="_blank" className='getStarted '>Get Started</a>
                </button>

              </div>
              <div className="demo-content">
                <img className='icon-CmdCenter icon' src={iconDealView}></img>
                <h5 className="demoName">Deal View Demo</h5>
                <p className="graphikregular font-italic pwd-align mb-4"><u className='copy_Password' onClick={() => this.copyPasswordHandler('OpsInteractive', 'DV')}>{this.state.passwordCopiedDV ? `Password Copied` : `Click here to copy the password for Deal View`}</u></p>
                {/* <p className="graphikregular pwd-align mb-3"></p> */}
                <button className='getStarted'>
                  <a href="https://accentureoperations.invisionapp.com/share/F6123Y4NY34N" className='getStarted ' target="_blank" >Get Started</a>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className='container-fluid py-0'>
          <header className='row'>
            <div className='header-left col-6'>
              <div className="media">
                <img className='align-self-center synopsLogo mr-2' height='49px' width='51px' src={Logo}></img>
                <div className="media-body">
                  <h5 className="appName graphiksemibold">SynOps Hub</h5>
                  <p className='graphikregular appdesc'>A platform that enables digital, data-driven, intelligent operations</p>
                </div>
              </div>
            </div>
            <div className='header-right col-6'>
              <p className='graphikmedium userName'>
                <img className='profileIcon' src={userIcon} onClick={() => this.handleProfileBtnClick()}></img>
              </p>
            </div>
          </header>
          <div className='body-container'>
            <div className='row'>
              <div className='col-3'>
                <h1 className='power my-0'>POWERING</h1>
                <h1 className='intOps my-0'>INTELLIGENT OPERATIONS</h1>
              </div>
            </div>
            <div className='d-flex align-items-baseline tile-container'>
              {this.state.hasCCAccess ? <div className='col-3 tile mt-5 '>
                <p className='dealName'>Command Center</p>
                <p className='description'>Operations View for Command Center team</p>
                <button className='getStarted' onClick={() => this.redirectPage('/commandcenter')}>Get Started</button>

              </div>
                : this.state.hasDealViewAccess ?
                  <div className='col-3 tile mt-5'>
                    <p className='dealName'>Deal View</p>
                    <p className='description'>Operations View for Deal View team</p>
                    <button className='getStarted' onClick={() => this.redirectPage('/dealview')}>Get Started</button>
                  </div> : ""
              }
              <div className='col-3 tile mt-5'>
                <div className='icon-CmdCenter ' src={iconDemo}></div>
                <p className='dealName'>Demo</p>


                <p className='description'>Click here for a walkthrough of Command Center and Deal View.
                </p>
                <p className='description'> You need to copy the password to access the demo. In demo page, you will see various interactive sections, click on it
                  or use left and right directional arrows on your keyboard for a walkthrough.
                </p>
                <button className='getStarted' onClick={(e) => this.democlick(e)}>View Demo</button>
              </div>
              {this.state.hasCCAccess || this.state.hasDealViewAccess ?
                "" : <div className='col-3 tile requestButton mt-5'>
                  <p className='dealName'>Uh, oh</p>
                  <p className='description descRequestAccess'>We are sorry, but you don’t have access to view the apps</p>
                  <a href="mailto:Synops-Support@accenture.com?subject=Access Request​&body=Hi Support team%0D%0A%0D%0APlease enable access for below ID.%0D%0A%0D%0AEnterprise ID%20%3A%0D%0A%0D%0AReason for Access%20%3A" className="getStarted requestAccess">Request Access</a>
                </div>}
            </div>
            {this.state.hasCCAccess && this.state.hasDealViewAccess ? <div style={{ visibility: !this.state.hasDealViewAccess ? 'hidden' : '', marginLeft: "1.4rem" }} >
              <div className='col-3 top-position-app'>
                <p className='dealName'>Deal View</p>
                <p className='description'>Operations View for Deal View team</p>
                <button className='getStarted' onClick={() => this.redirectPage('/dealview')}>Get Started</button>
              </div>
            </div> : <div className='blank_space'></div>}
          </div>
          <div className="profileCntnrDiv text-left" hidden={this.state.hideProfileHolderDiv}>
            <div className="mb-3">
              <a className="profileCloseBtnAncr" onClick={() => this.handleCloseProfileBtnClick()}>
                <img className="profileCloseBtn" src={hmcccircleClose} />
              </a>
            </div>
            <div className="row mt-2">
              <div className="col-10"><h6 className="graphikmedium " style={{ fontSize: "16px" }}>{i18n.t('Profile')}</h6></div>
              {/* <div className="col-2 text-right"><img className="profileIcon hidden" src={editgreyIcon} /></div> */}
              <div className="col-12 mt-4">
                <div className="media">
                  <img className="mr-2 profile-img" src={profileIconHome} />
                  <div className="media-body">
                    <a target="_blank" href="https://people.accenture.com/" className="ProfileName mb-1 userName">{this.state.profileName}</a>
                    <p className="ProfilePlace small"></p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row mt-4 pt-1">
              <div>
              <div className="col-6"><h6 className="graphikmedium" style={{ color: "gray" }}>{i18n.t('Dark Theme')}</h6></div>
              <div className="col-6" id="switch-hldr-hmcc">
                <div className="switchContainerTheme disable">
                  <label>
                    <input checked={this.state.isCheckedTheme} onChange={this._handleChangeTheme} className="switchTheme DarkTheme" type="checkbox" />
                    <div>
                      <div>
        
                      </div>
                    </div>
                  </label>
                </div>

              </div>
              </div>
            </div> */}
            <div className="row mt-5 pt-1">
              <div className="col-12 mb-2"><h6 className="graphikmedium">{i18n.t('Language')}</h6></div>
              <div className="dropdown" id="dropdown1">
                <button className={"hmcc-drop-btn  language-dropDown "}
                  style={{ marginLeft: "1rem", cursor: "pointer" }}
                //  onClick={() => this.toggleDropdown("Select Language")}
                >
                  <div
                    className='graphikmedium' style={{ color: "rgb(239, 242, 245)", fontSize: "0.8rem" }}

                  >{
                      "English"
                    }
                  </div>
                  {/* <img 
                                    style={{marginRight:"-0.8em",marginTop:"-0.4em"}}
                                    src={dropdownArrow} />{" "}
                                <div
                                    className='row m-0 mt-2'>
                                </div> */}
                </button>
                <div
                  className="languageDropdown-options"
                  hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}

                >
                  {/* <div
                                    id="searchbox"
                                    className="hmcc-searchbox"
                                    hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}
                                  //  hidden={this.state.openedDropdown == "Select Language" ? this.state.searchedLanguageText : ''}
                                >
                                    {this.state.openedDropdown == "Select Language" ? (
                                        <input
                                            type="text"
                                            className="homeLanguageSearch languageSearch graphikregular"
                                            placeholder={i18n.t('Search') + '..'}
                                            id="myInput"
                                            onChange={this.onChangeLanguage}
                                            value={this.state.searchedLanguageText}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div> */}
                  {/* {this.state.languageListFiltered.map(languages => {
                                    return (
                                        <div id='myDropdownB9' onClick={() => this.onSelectLanguage(languages)} className={
                                            this.state.openedDropdown == "Select Language" ? "dropdown-content graphikregular show" : "dropdown-content graphikregular"
                                        }>
                                            {i18n.t(languages.languageDescription)}
                                        </div>
                                    )
                                })} */}

                </div>
              </div>

            </div>

            <div
              className="logoutIcon"
              onClick={() => {
                this.logout();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span className="ml-1">  Logout </span>
            </div>
          </div>
          <div className='footer-space'>
          </div>
          <div className='footer-up row'>
            <div className='col-1 acclogo'>
              <ul>
                <li><img className='acc-logo' src={accLogo}></img></li>
              </ul>
            </div>
            <div className='col-7 otherlinks'>
              <ul>
                <li className='ml-4'><a target='_blank' href='https://in.accenture.com/technologyforoperations/toolsnavigator/'>Tools Navigator</a></li>
                <li><a target='_blank' href='https://in.accenture.com/protectingaccenture/terms-of-service-and-privacy-notice/'>Terms of Use</a></li>
              </ul>
            </div>
            <div className='col-4 copyright text-right'>
              <ul>
                <li><a>© {(new Date().getUTCFullYear())} Accenture. All Rights Reserved.</a></li>
                <li><img onClick={() => openFeedbackModal()} className='helpIcon' src={feedback}></img></li>
              </ul>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showFeedbackModal}
          onHide={() => hideModal()}
          dialogClassName="feedback-modal"
          size="lg"
        >
          <Modal.Header closeButton className="feedback-modal-header">
            <Modal.Title className="feedback-modal-title"><img height='24px' width='24px' src={feedback1}></img> &nbsp;&nbsp; <span style={{ fontSize: "1rem" }}>Support and Feedback</span></Modal.Title>
          </Modal.Header>
          <Modal.Body
            id='feedback_Body'
            // className="feedback-modal"
            style={{ minHeight: "66vh" }}
          >

            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                {/* <Form.Label className="feedback-label">What type of feedback would you like to provide?</Form.Label> */}
                <div className='row mt-5'>
                  <div className='feedback-productInfo' onClick={() => this.handleFeedbackChange("type", 'productInfo')}>
                    {this.state.type != 'productInfo' && (<img src={signal} className="feedbackNewIdea"></img>)}
                    {this.state.type == 'productInfo' && (<img src={signalSelected} className="feedbackNewIdea"></img>)}
                    {this.state.type != 'productInfo' && (<div className="feedbackLabel">Product Information</div>)}
                    {this.state.type == 'productInfo' && (<div className="feedbackLabelSelect">Product Information</div>)}
                    {this.state.type == 'productInfo' && (<div className="selectedFeedbackType"></div>)}
                  </div>
                  {/* <div className='feedback-general' onClick={() => this.handleFeedbackChange("type", 'general')}>
                                {this.state.type != 'general' && (<img src={general}></img>)}
                                {this.state.type == 'general' && (<img src={generalSelected}></img>)}
                                {this.state.type != 'general' && (<div className="feedback-type-label">Raise an issue</div>)}
                                {this.state.type == 'general' && (<div className="feedback-type-labelSelect">Raise an issue</div>)}
                                {this.state.type == 'general' && (<div className="selected-type"></div>)}
                              </div> */}
                  <div className='feedback-newidea' onClick={() => this.handleFeedbackChange("type", 'newIdea')}>
                    {this.state.type != 'newIdea' && (<img src={newIdea} className="feedbackNewIdea"></img>)}
                    {this.state.type == 'newIdea' && (<img src={newIdeaSelected} className="feedbackNewIdea"></img>)}
                    {this.state.type != 'newIdea' && (<div className="feedbackLabel" >Report an Issue, Request Access & Feedback</div>)}
                    {this.state.type == 'newIdea' && (<div className="feedbackLabelChange" >Report an Issue, Request Access & Feedback</div>)}
                    {this.state.type == 'newIdea' && (<div className="selectedFeedbackType" ></div>)}                  </div>
                  {/* <Button variant="outline-dark" onClick={()=>this.handleFeedbackChange("type", 'general')}>General</Button>	
                              <Button variant="outline-dark" onClick={()=>this.handleFeedbackChange("type", 'newIdea')}>New Idea</Button> */}
                </div>
                {this.state.type == 'productInfo' && (
                  <div>
                    <div className="row">
                      <div className="col-1">
                        <div className="row">
                          <div className="large-8 large-centered ml-4">
                            <div className="wrapper">
                              <div className="circle">
                                <h5 className="total-issues">1</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-11">
                        <div className="mt-2"><a href="https://in.accenture.com/technologyforoperations/toolsnavigator/synops-core-experience/synops-hub/" target="_blank" style={{ textDecoration: "underline" }}>Click here to open the product information page</a></div>
                      </div>
                    </div>
                  </div>

                )}
                {this.state.type == 'general' && (
                  <div>
                    <div className="row">
                      <div className="col-1">
                        <div className="row">
                          <div className="large-8 large-centered ml-4">
                            <div className="wrapper">
                              <div className="circle">
                                <h5 className="total-issues">1</h5>


                              </div>
                              <div className="vertical-line"></div>
                            </div>
                            <div className="wrapper">
                              <div className="circle">
                                <h5 className="total-issues">2</h5>
                              </div>
                              <div className="vertical-line"></div>
                            </div>
                            <div className="wrapper">
                              <div className="circle">
                                <h5 className="total-issues">3</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-11">
                        <div className="mt-2"><b>To report issues or new access, please raise a ticket </b></div>
                        <div className="mt-5"><b>Know more about </b><a href="https://kxdocuments.accenture.com/contribution/a1efbc5a-1339-4898-9e62-4c33eeb415e8" target="_blank" style={{ textDecoration: "underline" }}>  how to raise a ticket</a></div>
                        <div className="mt-5"><a href=" https://bps-portal.accenture.com/portal/pages/Accenture/home" target="_blank" style={{ textDecoration: "underline" }}> Click here to raise a ticket</a></div>
                      </div>
                    </div>
                  </div>

                )}
                {(this.state.type == 'newIdea' && !this.state.feedbackSubmitted) && (
                  <div>
                    <div className="row feedbackradioButtons">
                      <div className="ml-2">
                        <input
                          type="radio"
                          name="app_name"
                          value="Report an Issue"
                          defaultChecked
                          onChange={(e) =>
                            this.handleMessageChange("Report an Issue", e.target.value)
                          }
                        />{" "}
                        Report an Issue
                      </div>
                      <div className="ml-2" style={{ marginTop: "1px" }}>
                        <input
                          type="radio"
                          name="app_name"
                          value="Access"
                          onChange={(e) =>
                            this.handleMessageChange("Access", e.target.value)
                          }
                        />{" "}
                        Access
                      </div>
                      <div className="ml-2" style={{ marginTop: "2px" }}>
                        <input
                          type="radio"
                          name="app_name"
                          value="Feedback"
                          onChange={(e) =>
                            this.handleMessageChange("Feedback", e.target.value)
                          }
                        />{" "}
                        Feedback
                      </div>
                    </div>

                    <Select
                      className='feedback-select'
                      placeholder='Select Application'
                      onChange={(e) =>
                        this.handleFeedbackChange("suggestion", e)
                      }
                      options={this.state.newIdeaSuggestions}
                      value={this.state.feedbackSelected}
                    />
                  </div>)}
              </Form.Group>
              {(this.state.type == 'newIdea' && !this.state.feedbackSubmitted) && (
                <div>
                  <Form.Group controlId="exampleForm.ControlTextarea1" style={{ marginLeft: '16px' }}>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      //  maxLength={500}
                      placeholder={this.state.commentText}
                      onChange={(e) =>
                        this.handleFeedbackChange("feedbackMessage", e.target.value)
                      }
                      value={this.state.feedbackBody}
                      style={{ resize: "vertical", minHeight: "100px" }}
                    >
                    </Form.Control>
                  </Form.Group>
                  <div class="row">
                    <div class="col-md-12">

                      <label for="filePicker" className="attachmentPosition" onChange={this.handleFile} >
                        <FontAwesomeIcon className="plusButton" icon={faPlusCircle} />
                        Add Attachment
                        <input id="filePicker" style={{ visibility: "hidden", marginLeft: "-19rem" }} type="file" multiple />
                      </label>
                      <div className="fileColor filetypeFont">(.jpg, .png, .pdf, .docx)</div>
                      <div className="fileColor attachmentFont">**(3 attachments upto 15 MB are permitted)</div>

                      {(this.state.selectedFile.length > 0) &&
                        // this.state.selectedFile.map(files => <p style={{float:"right"}}>{files[2].name}</p>)
                        <p className='uploaded_file'>

                          {this.state.selectedFile.map((name, index) => <p style={{ fontWeight: "bold", marginBottom: "0" }}>{name.name}
                            <span key={index} onClick={() => this.deleteIcon(index)} className="ml-2 crossIcon" style={{ cursor: "pointer" }}>x</span>
                          </p>)}

                        </p>
                      }

                    </div>
                    <div className='col-md-12 text-right'>
                      {this.state.fileExtensionError && (
                        <span className="fileUploadMessage">
                          Please upload valid file.
                        </span>
                      )}
                      {this.state.fileSizeError && (
                        <span className="fileSizeMessage">
                          The file you are attaching exceeds the specified limit.
                        </span>
                      )}
                      {this.state.totalfileCountError && (
                        <span className="fileErrorMessage">
                          Maximum 3 files can be uploaded.
                        </span>
                      )}
                      {this.state.duplicateFileErrorMsg && (
                        <span className="fileErrorMessage">
                          File has already been uploaded.
                        </span>
                      )}
                    </div>

                  </div>
                </div>

              )}

              {(this.state.type == 'newIdea' && this.state.feedbackSubmitted) && (
                <div className="text-center">

                  <img src={feedbackSaved} height='24px' width='24px' className="feedback-submit"></img>
                  <span className="feedbackSubmittext">{this.state.submittedIssue} Submitted Successfully</span>
                  <div className="newFeedback">
                    <span className="newFeedbackSubmitted" onClick={() => this.feedbackSubmitNew()}
                    >Click here </span>
                    to provide new {this.state.popUpText}.

                  </div>
                </div>
              )

              }
            </Form>
          </Modal.Body>
          {this.state.type == 'general' &&
            (<Modal.Footer className="feedback-footer-general">

            </Modal.Footer>)}
          {this.state.type == 'newIdea' && !this.state.feedbackSubmitted &&
            (<Modal.Footer className="feedback-footer">

              <Button className='footer-btn' disabled={(this.state.feedbackBody.trim().length == 0)} onClick={() => this.generateNewIdeaScript()}>Submit</Button>

            </Modal.Footer>)}
        </Modal>
        <Modal
          show={this.state.openSubmit}
          onHide={() => this.hideSubmit()}
          dialogClassName="feedback-modal"
          className="feedbackSubmitted-modal"
          backdrop="static"
        >
          <Modal.Header closeButton className="feedback-modal-header-submit">
            <Modal.Title className="feedback-modal-title"><img height='24px' width='24px' src={feedback1}></img> &nbsp;&nbsp; <span>Support and Feedback</span></Modal.Title>
          </Modal.Header>
          <Modal.Body
            // className="feedback-modal"	
            style={{ height: "32vh" }}
          >
            <div >

              <div style={{ padding: "25px" }}>
                Do you want to submit this {this.state.popUpText} ?
                <div className="row">
                  <div className="col-12 pr-0 text-right mt-5">

                    <Button
                      className='footer-btn'
                      onClick={() => this.feedbackSubmit()}>Yes</Button>
                    <Button
                      className='footer-btn ml-3'
                      onClick={() => this.hideSubmit()}>No</Button>

                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.appReducer,

  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUserPreferedLanguage: (code, name) => {
      dispatch(setUserPreferedLanguage(code, name));
    },
    setLanguageTranslations: (arr) => {
      dispatch(setLanguageTranslations(arr));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withMsal(App));